export const RoutesPaths = {
  LOGIN: "/",
  SIGNUP: "/signup",
  FORGOTPASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  CAMPAIGN: "/campaign",
  CAMPAIGNFOLLOWERS: "/campaign/followers",
  CALANDER: "/calander",
  SETTINGS: "/settings",
  MESSAGEDETAIL: "/messages/:id",
  MESSAGES: "/messages",
  ACCOUNTNOTFOULD: "/account-not-found",
  RESETPASSWORD: "/reset-password",
  LINKEDINOPTIMIZER: "/linkedIn-Optimizer",
};

// export const backendUrl = "http://192.168.1.16:3001/api/";
export const backendUrl = "https://sherpai.us/api/";
export const backendUrlImg = "https://sherpai.us/uploads/";
export const calendlyUrl = "https://auth.calendly.com/oauth/authorize";
export const clientId = "gJNB7zfBCFkdTymG9RI4cQG5QIMg7arn4GJ4kISYcsk";
export const calendlyRedirect = "https://sherpai.us/calander";
// export const calendlyRedirect = "http://localhost:3001/calander"; 

export const messenger_contact_status_choices = {
  1: "Contact",
  2: "New Contact",
  3: "Connect Requested",
  4: "Disconnected",
  11: "Searched",
  21: "Imported",
  34: "Revoked",
};

export const messenger_contact_status_choices_color = {
  1: "blue",
  2: "green",
  3: "blue",
  4: "red",
  11: "purple",
  21: "teal",
  34: "red",
};

export const messenger_conversation_status_choices = {
  5: "No Interaction",
  6: "Awaiting Reply",
  7: "Replied",
  24: "Email required to connect",
};


export const messenger_conversation_status_choices_color = {
  5: "blue",
  6: "yellow",
  7: "green",
  24: "grey",
};