import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";
import { Popover, Typography } from "@mui/material";
import authService from "../services/auth.service";
import moment from "moment";

export default function Header() {
  const location = useLocation();
  const pathname = location?.pathname;
  let title;
  const { campaignName } = location.state || {};
  const [notificationData, setNotificationData] = useState();

  const pathParts = pathname.split("/");
  const message = pathParts[1];
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  switch (true) {
    case location.pathname === "/dashboard":
      title = "Home Page";
      break;
    case location.pathname === "/campaign":
      title = "My Campaigns";
      break;
    case /^\/campaign\/followers\/\d+$/.test(location.pathname):
      title = campaignName ? campaignName : "";
      break;
    case location.pathname === "/calander":
      title = "Calendar";
      break;
    case `/${message}` === "/messages":
      title = "Message";
      break;
    case location.pathname === "/settings":
      title = "Settings";
      break;
      case location.pathname === "/linkedIn-Optimizer":
        title = "LinkedIn Optimizer";
        break;
    default:
      title = pathname.replace("-", " ");
      break;
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if(localStorage.getItem("authToken")){
      notification();
    }
  }, []);

  const notification = async () => {
    try {
      const response = await authService.notification(1,20);
      if (response.data.status === 1) {
        setNotificationData(response.data.data);
      } else {
        setNotificationData();
      }
    } catch{
      setNotificationData();
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM.DD.YYYY");
  };

  const handlePageChange = (index, id) => {
    setActiveIndex(index);
    if (id) {
      navigate(`${RoutesPaths.MESSAGES}/${id}`);
    }
  };

  return (
    <>
      <div className="container-fluid headersectionShade">
        <div className="row">
          <div className="col-md-12 headerSection">
            <div>
              <h4 className="m-0 headertitle">{title}</h4>
            </div>
            <div className="headerRightSection">
              <img
                src="/assets/img/notification.svg"
                alt="user"
                className="notificationIcon"
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography className="notificationForm">
                  <div className="row popOver">
                    <div className="col-md-12 notificationTitle">
                      Notification
                    </div>
                    {notificationData && notificationData.length > 0 ? (
                      notificationData.map((notification, index) => (
                        <div
                          className={`col-md-12 cursorPointer notificationSection ${
                            activeIndex === index ? "notificationActive" : ""
                          }`}
                          key={index}
                          onClick={() =>
                            handlePageChange(index, notification?.referData)
                          }
                        >
                          <div className="notificatioHeader">
                            <div className="notificationtext m-0">
                              {notification.title}
                            </div>
                            <div className="notificationtext m-0">
                              {formatDate(notification.createdAt)}
                            </div>
                          </div>
                          <div className="notificationMessage m-0">
                            {notification.message}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-md-12 popOver">
                        <div className="notificationTitle p-0">
                          No notifications found
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="paginationInteger">
                    <div className="borderSection">
                      <img
                        src="/assets/img/arrowLeft.svg"
                        alt=""
                        className="logo"
                      />
                    </div>
                    <div>1</div>
                    <div className="borderSection">
                      <img
                        src="/assets/img/arrowRight.svg"
                        alt=""
                        className="logo"
                      />
                    </div>
                  </div> */}

                </Typography>
              </Popover>
              <NavLink to={RoutesPaths.SETTINGS} className="activePath">
                <div className="profileImage">
                  <img src="/assets/img/profile.jpg" alt="user" />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
