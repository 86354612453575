import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { RoutesPaths } from './constant';

function PublicRoute({ children }) {
    const token = localStorage.getItem('authToken') || null;
    if (token) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={RoutesPaths.DASHBOARD} />;
    }
    // authorized so return child components
    return children;
}

PublicRoute.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PublicRoute;
