import React, { useEffect, useRef, useState } from "react";
import {
  messenger_contact_status_choices,
  messenger_contact_status_choices_color,
  messenger_conversation_status_choices,
  messenger_conversation_status_choices_color,
} from "../../Router/constant";
import { useParams } from "react-router-dom";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { Controller, useForm } from "react-hook-form";
import campaignService from "../../services/campaign.service";
import messageService from "../../services/message.service";

export default function Campaignfollowers() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [followers, setFollowers] = useState([]);
  const { id } = useParams();
  const [campaignTotal, setCampaignTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [popUpLoading, setPopupLoading] = useState(false);
  // const location = useLocation();
  const [messsageUser, setMesssageUser] = useState();
  const [recevedMsg, setrecevedMsg] = useState();
  const bottomRef = useRef(null);

  useEffect(() => {
    campaignList(currentPage);
  }, [currentPage]);

  const campaignList = async (page = 1, pageSize = 10, searchData) => {
    setLoading(true);
    try {
      const response = await campaignService.getCampaignsFollwers(
        id,
        page,
        pageSize,
        searchData
      );
      if (response.data.status === 1) {
        setFollowers(response.data.data);
        setCampaignTotal(response.data.total);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY hh:mm A");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onSubmit = async (data) => {
    campaignList(1, 10, data.search);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // Maximum number of visible pages
    const totalPages = Math.ceil(campaignTotal / pageSize);

    // Calculate start and end indices for pagination
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (totalPages > maxVisiblePages) {
      if (endPage === totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
      } else if (startPage === 1) {
        endPage = maxVisiblePages;
      }
    }

    if (startPage > 1) {
      pageNumbers.push(
        <div
          key={1}
          className={`paginationLength ${currentPage === 1 ? "PaginaitonActive" : ""
            }`}
          onClick={() => handlePageChange(1)}
        >
          {1}
        </div>
      );
      if (startPage > 2) {
        pageNumbers.push(<div key="startEllipsis">...</div>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <div
          key={i}
          className={`paginationLength ${i === currentPage ? "PaginaitonActive" : ""
            }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </div>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<div key="endEllipsis">...</div>);
      }
      pageNumbers.push(
        <div
          key={totalPages}
          className={`paginationLength ${currentPage === totalPages ? "PaginaitonActive" : ""
            }`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </div>
      );
    }

    return pageNumbers;
  };

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [modalMessageOpen, setMessageModal] = useState(false);

  const handleInputChange = (event) => {
    setMessage(event.target.value); // Update the state with the textarea value
    event.target.style.height = "23px"; // Reset textarea height
    event.target.style.height = `${event.target.scrollHeight}px`; // Set new textarea height
  };

  const handleSendMessage = async (id) => {
    if (message.trim()) {
      setMessages([...messages, { body: message }]);
      setMessage("");
      let payload = {
        message: message,
        messenger: id,
      };
      await messageService.postSendMessage(payload);
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const openModal = async (messageUser) => {
    setMesssageUser(messageUser);
    setMessageModal(true);
    setPopupLoading(true);
    try {
      const response = await messageService.getContactMessage(messageUser?.id);
      if (response.data.status === 1) {
        setrecevedMsg(response.data.data);
        setPopupLoading(false);
      } else {
        setPopupLoading(false);
      }
    } catch {
      setPopupLoading(false);
    }
  };

  const closeModal = () => {
    setMessageModal(false);
  };

  return (
    <div className="container-fluid campaignHeight">
      <div className="row campaignSection">
        <div className="col-md-12 p-0">
          <div className="outertableSection">
            <div className="campaignProfileHeader">
              <div>
                <h6 className="tableSectiontitle">People List</h6>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="searchSection">
                  <Controller
                    name="search"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        type="search"
                        placeholder="Enter words to filter ....."
                        className="customSearch"
                        {...field}
                      />
                    )}
                  />
                  {errors.email && (
                    <p className="errorMessage">{errors.search.message}</p>
                  )}
                  <button>Search</button>
                </div>
              </form>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="comapignTRtitle">
                      <th className="min-w-140px CampaignName">Name</th>
                      <th className="text-center min-w-140px">Last Message</th>
                      <th className="text-center min-w-140px">Status</th>
                      <th className="text-center min-w-140px">Active</th>
                    </tr>
                  </thead>
                  <tbody className="fw-semibold text-gray-600">
                    {loading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <div className="skeletonCampaign">
                            {[...Array(10)].map((_, index) => (
                              <ContentLoader
                                key={index}
                                width={"100%"}
                                height={55}
                              >
                                <rect
                                  x="0"
                                  y="0"
                                  rx="10"
                                  ry="10"
                                  width="100%"
                                  height="55"
                                />
                              </ContentLoader>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ) : followers && followers.length > 0 ? (
                      followers.map((campaign, index) => (
                        <tr key={index} className="comapignTRData">
                          <td className="Datatitle CampaignName">
                            <div className="followersProfileSection">
                              <img
                                src={campaign?.contact?.image_base64}
                                alt="profile"
                                className="followersProfile"
                              />
                              <div className="profileName">
                                <p className="m-0">{campaign?.contact?.name}</p>
                                <span>{campaign?.contact?.email}</span>
                              </div>
                            </div>
                          </td>
                          <td className="text-center verticalCenter">
                            {formatDate(campaign.updated)}
                          </td>
                          <td className="text-center verticalCenter">
                            <div className="alignSection">
                              <div
                                className="dotcontactStatus"
                                style={{
                                  backgroundColor:
                                    messenger_contact_status_choices_color[
                                    campaign?.contact_status
                                    ],
                                }}
                              ></div>
                              {
                                messenger_contact_status_choices[
                                campaign?.contact_status
                                ]
                              }
                            </div>

                            <div className="alignSection">
                              <div
                                className="dotcontactStatus dotConversationStatus"
                                style={{
                                  backgroundColor:
                                    messenger_conversation_status_choices_color[
                                    campaign?.conversation_status
                                    ],
                                }}
                              ></div>
                              {
                                messenger_conversation_status_choices[
                                campaign?.conversation_status
                                ]
                              }
                            </div>
                          </td>

                          <td className="text-center verticalCenter">
                            <div
                              className="cursorPointer"
                              onClick={() => openModal(campaign)}
                            >
                              <img
                                src="/assets/img/messageblack.svg"
                                alt="icon"
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No People found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {!loading && followers.length > 0 && (
                <div className="paginationMainSection">
                  <div>
                    <h6 className="paginationItem m-0">Items : {campaignTotal}</h6>
                  </div>
                  <div className="paginationInteger">
                    <div
                      className={`borderSection ${currentPage === 1 ? "disabled" : ""
                        }`}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <img
                        src="/assets/img/arrowLeft.svg"
                        alt=""
                        className="logo"
                      />
                    </div>
                    {renderPageNumbers()}
                    <div
                      className={`borderSection ${currentPage === Math.ceil(campaignTotal / pageSize)
                          ? "disabled"
                          : ""
                        }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <img
                        src="/assets/img/arrowRight.svg"
                        alt=""
                        className="logo"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {modalMessageOpen && (
          <div className="modal messageModal">
            <div className="modal-contentConfirm mainPopUpMessage">
              <img
                src="/assets/img/close-box.svg"
                alt="icon"
                className="closeIcon"
                onClick={closeModal}
              />
              <div className="popUpMessage">
                <img src={messsageUser?.contact?.image_base64} alt="" />
                <div>
                  <p className="m-0">{messsageUser?.contact?.name}</p>
                  <span className="m-0">{messsageUser?.contact?.email}</span>
                </div>
              </div>

              <div className="messageBoxSection">
                <div className="messageBoxSectionInner">

                  {popUpLoading ? (
                    <ContentLoader width={"100%"} height={55}>
                      <rect
                        x="0"
                        y="0"
                        rx="10"
                        ry="10"
                        width="100%"
                        height="55"
                      />
                    </ContentLoader>
                  ) : (
                    <>
                      {[...recevedMsg, ...messages].map((msg, index) => {
                        if (msg.body !== "") {
                          return (
                            <div key={index} className={msg.send_by_id === null ? "recevedMessage" : "recevedMessage sendMessage"}>
                              {msg.send_by_id === null ? (
                                <>
                                  <p className="m-0">{msg.body}</p>
                                </>
                              ) : (
                                <>
                                  <p className="m-0">{msg.body}</p>
                                </>
                              )}
                              <div ref={bottomRef}></div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  )}
                </div>

              </div>

              {messsageUser?.contact_status === 1 ||
                messsageUser?.contact_status === 2 ? (
                <div className="messageTextBox">
                  <textarea
                    type="text"
                    placeholder="Type in a message"
                    className="popUpMessageBox"
                    value={message}
                    onChange={handleInputChange}
                  ></textarea>
                  <div className="popupSendIcon">
                    <img
                      src="/assets/img/sendIcon.svg"
                      alt=""
                      className="sendPopUplogo"
                      style={{ cursor: "pointer" }}
                      // onClick={handleSendMessage}
                      onClick={() => handleSendMessage(messsageUser.id)}
                    />
                  </div>
                </div>
              ) : (
                <div className="messageTextBox">
                  <p className="m-0">
                    You are not connected to this person and you cannot send
                    them further messages
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
