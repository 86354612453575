import React, { useState } from "react";
import { RoutesPaths, backendUrl } from "../Router/constant";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { Button } from "reactstrap";
import { CircularProgress } from "@mui/material";

export default function Sidebar() {
  const location = useLocation();
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const pathname = location?.pathname;
  const pathParts = pathname.split("/");
  const message = pathParts[1];

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  const handleCloseModal = () => {
    setLogoutModalOpen(false);
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("authToken");
      const response = await axios.post(`${backendUrl}logout`, null, {
        headers: {
          Authorization: `${token}`,
        },
      });
      if (response.data.status === 1) {
        localStorage.removeItem("authToken");
        navigate(RoutesPaths.LOGIN);
        toast.error(response.data.data);
        setIsLoading(false);
      } else {
        toast.error("Something went wrong");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.data);
    }
  };

  return (
    <>
      <div className="container-fluid sideBarSection">
        <div className="row">
          <div className="col-md-12">
            <div
              className={`sidebarLogo dispayWeb ${
                `/${message}` === RoutesPaths.MESSAGES ? "bigLogo" : ""
              }`}
            >
              <img src="/assets/img/Sherpa_Logo_sideBar.svg" alt="user" />
            </div>

            <div
              className={`displayNone ${
                `/${message}` === RoutesPaths.MESSAGES ? "displayShow" : ""
              }`}
            >
              <img src="/assets/img/Sherpasmall logo.svg" alt="user" />
            </div>

            <div className="sidebarLogo dispayMobile">
              <img src="/assets/img/Sherpasmall logo.svg" alt="user" />
            </div>

            <div>
              <NavLink to={RoutesPaths.DASHBOARD} className="decorationNone">
                <div
                  className={`sideLinks ${
                    location.pathname === RoutesPaths.DASHBOARD ? "active" : ""
                  } ${
                    `/${message}` === RoutesPaths.MESSAGES
                      ? "justifyCenter"
                      : ""
                  }`}
                >
                  <img src="/assets/img/dashboardFolder.svg" alt="Dashboard" />
                  <h6
                    className={`m-0 titleText ${
                      `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                    }`}
                  >
                    Dashboard
                  </h6>
                </div>
              </NavLink>

              {/* <NavLink to={RoutesPaths.CAMPAIGN} className="decorationNone">
                <div
                  className={`sideLinks ${
                    location.pathname === RoutesPaths.CAMPAIGN ? "active" : ""
                  } ${location.pathname === RoutesPaths.MESSAGES ? "justifyCenter" : ""}`}
                >
                  <img src="/assets/img/compaign.svg" alt="Campaign" />
                  <h6 className={`m-0 titleText ${location.pathname === RoutesPaths.MESSAGES ? "hideText" : ""}`}>My campaign</h6>
                </div>
              </NavLink> */}

              {/* <NavLink
                to={RoutesPaths.CAMPAIGNFOLLOWERS}
                className="decorationNone"
              > */}
              {/* <div
                className={`sideLinks ${
                  location.pathname === RoutesPaths.CAMPAIGNFOLLOWERS
                    ? "active"
                    : ""
                }`}
              >
                <img src="/assets/img/followers.svg" alt="Followers" />
                <h6 className="m-0 titleText">Followers</h6>
              </div> */}
              {/* </NavLink> */}

              <NavLink to={RoutesPaths.CALANDER} className="decorationNone">
                <div
                  className={`sideLinks ${
                    location.pathname === RoutesPaths.CALANDER ? "active" : ""
                  } ${
                    `/${message}` === RoutesPaths.MESSAGES
                      ? "justifyCenter"
                      : ""
                  }`}
                >
                  <img src="/assets/img/calander.svg" alt="Calendar" />
                  <h6
                    className={`m-0 titleText ${
                      `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                    }`}
                  >
                    Calendar
                  </h6>
                </div>
              </NavLink>

              <div
                onClick={() => {
                  navigate(`${RoutesPaths.MESSAGES}`);
                }}
                // to={`${RoutesPaths.MESSAGES}/${contacts[0]?.id}`}
                className="decorationNone"
              >
                <div
                  className={`sideLinks ${
                    `/${message}` === RoutesPaths.MESSAGES ? "active" : ""
                  } ${
                    `/${message}` === RoutesPaths.MESSAGES
                      ? "justifyCenter"
                      : ""
                  }`}
                >
                  <img src="/assets/img/messeges.svg" alt="Message" />
                  <h6
                    className={`m-0 titleText ${
                      `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                    }`}
                  >
                    Messages
                  </h6>
                </div>
              </div>

              <NavLink to={RoutesPaths} className="decorationNone">
                <div
                  className="sideLinks">
                  <img src="/assets/img/AIIcon.svg" alt="AI" />
                  <h6  className={`m-0 titleText ${
                      `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                    }`}
                  >
                    AI Matching
                  </h6>
                </div>
              </NavLink>

              <NavLink to={RoutesPaths.SETTINGS} className="decorationNone">
                <div
                  className={`sideLinks ${
                    location.pathname === RoutesPaths.SETTINGS ? "active" : ""
                  } ${
                    `/${message}` === RoutesPaths.MESSAGES
                      ? "justifyCenter"
                      : ""
                  }`}
                >
                  <img src="/assets/img/setting.svg" alt="Settings" />
                  <h6
                    className={`m-0 titleText ${
                      `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                    }`}
                  >
                    Settings
                  </h6>
                </div>
              </NavLink>

              <NavLink to={RoutesPaths.LINKEDINOPTIMIZER} className="decorationNone">
                <div
                  className={`sideLinks ${
                    location.pathname === RoutesPaths.LINKEDINOPTIMIZER ? "active" : ""
                  } ${
                    `/${message}` === RoutesPaths.MESSAGES
                      ? "justifyCenter"
                      : ""
                  }`}
                >
                  <img src="/assets/img/thunder.svg" alt="Settings" />
                  <h6
                    className={`m-0 titleText ${
                      `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                    }`}
                  >
                    LinkedIn Optimizer
                  </h6>
                </div>
              </NavLink>

            </div>

            {/* <NavLink to={RoutesPaths.LOGIN}> */}
            <div className="bottomLinks">
              <div className="sideLinks" onClick={handleLogoutClick}>
                <img src="/assets/img/logout.svg" alt="user" />
                <h6
                  className={`m-0 titleText ${
                    `/${message}` === RoutesPaths.MESSAGES ? "hideText" : ""
                  }`}
                >
                  Logout
                </h6>
              </div>
            </div>
            {/* </NavLink> */}
          </div>
          {isLogoutModalOpen && (
            <div className="modal">
              <div className="modal-contentConfirm">
                <h3 className="confirmationtitle">Confirm</h3>
                <img
                  src="/assets/img/close-box.svg"
                  alt="icon"
                  className="closeIcon"
                  onClick={handleCloseModal}
                />
                <h3 className="confirmationMessage">
                  Are you sure you want to logout?
                </h3>
                <div className="modal-actions">
                  <button
                    onClick={handleCloseModal}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                  <Button
                    onClick={handleLogout}
                    className="submitBtn btn logOutBtn"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={20} className="loaderScreen" />
                    ) : (
                      ""
                    )}
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
