import React, { useEffect, useState } from "react"; // <-- Add this line
import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";
import RouteComponent from "./Router/router";
import { RoutesPaths, backendUrl } from "./Router/constant";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import axios from "axios";
import { Toaster } from "react-hot-toast";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarHeaderEnabledState, SetisSidebarHeaderEnabledState] = useState(false);

  useEffect(() => {
    SetisSidebarHeaderEnabledState(
      [
        RoutesPaths.LOGIN,
        RoutesPaths.FORGOTPASSWORD,
        RoutesPaths.SIGNUP,
        RoutesPaths.ACCOUNTNOTFOULD,
        RoutesPaths.RESETPASSWORD
      ].includes(location.pathname)
    );
    verifyToken();
  }, [location]);

  const pathname = location?.pathname;
  const pathParts = pathname.split('/');
  const message = pathParts[1];

  const verifyToken = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        await axios.post(
          `${backendUrl}verifyToken`,
          {},
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
      }
    } catch (error) {
      localStorage.removeItem("authToken");
      if (error.response.data.status === 0) {
        navigate(RoutesPaths.LOGIN);
        // toast.error(error.response.data.data);
      }
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        containerStyle={{ zIndex: 123465789 }}
        toastOptions={{
          className: "",
          duration: 5000,
          success: {
            iconTheme: {
              primary: "var(--primary-color)",
            },
          },
        }}
      />
      {isSidebarHeaderEnabledState ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <RouteComponent />
            </div>
          </div>
        </div>
      ) : (
        <div className="landingSection">
          <div
            className={`sidebarslider ${
              `/${message}` === "/messages" ? "sidebarSliderMessages" : ""
            }`}
          >
            <Sidebar />
          </div>
          <div
            className={`rightDataSection ${
              `/${message}` === "/messages"
                ? "rightDataSectionMessages"
                : ""
            }`}
          >
            <Header />
            <div className="scrollSection">
              <RouteComponent />
              {`/${message}` !== "/messages" && <Footer />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
