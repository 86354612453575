import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import authService from "../services/auth.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";

export default function Otp({ otpData, emailData, onClose }) {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsButtonDisabled(otp.some((val) => val === ''));
  }, [otp]);

  const handleInput = (index) => (e) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus next input field if not the last one
      if (value.length === 1 && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleChange = (index) => (e) => {
    const value = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleKeyDown = (index) => (e) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '' && index > 0) {
        inputRefs.current[index - 1].focus();
      }
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const otpString = otp.join('');
    const payload = {
      id: otpData.id,
      otp: otpString,
    };
    try {
      const response = await authService.otpVerify(payload);
      if (response.data.status === 1) {
        navigate(RoutesPaths.RESETPASSWORD, { state: { id: otpData.id } });
        setIsLoading(false);
        toast.success("OTP Verify successful");
      } else {
        setIsLoading(false);
        toast.success("OTP Verify failed");
      }
    } catch (error) {
      toast.error(error?.data || "An error occurred");
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const resentOTP = async () => {
    const payload = {
      username: emailData.username,
    };
    try {
      const response = await authService.forgotPassword(payload);
      if (response.data.status === 1) {
        toast.success("OTP resend successfully");
      } else {
        toast.success("OTP resend failed");
      }
    } catch (error) {
      toast.error(error.response.data.data || "An error occurred");
    }
  }

  return (
    <>
      <div className="container-fluid otpScreenSection">
      <div className="row otpScreenRow">
        <div className="col-md-4 otpScreeninnerSection">
          <img
            src="/assets/img/close-box.svg"
            alt="icon"
            className="closeIconOTP"
            onClick={onClose}
          />
          <h6 className="loginHeaderTitle m-0">OTP Screen</h6>
          <p className="codeSentText">
            Enter OTP Code sent to {emailData.username}
          </p>
          <div className="optFill">
            {otp.map((value, index) => (
              <div key={index} className="col-md-2 otpInput">
                <input
                  type="tel"
                  maxLength="1"
                  size="1"
                  value={value}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={handleChange(index)}
                  onInput={handleInput(index)}
                  onKeyDown={handleKeyDown(index)}
                />
              </div>
            ))}
          </div>

          <p className="codeSentText">
            Did not receive OTP Code?<span className="resentCode" onClick={resentOTP}>Resend Code?</span>
          </p>
          <div className="submitButton">
            <Button
              type="button"
              className="submitBtn btnSection"
              onClick={handleSubmit}
              disabled={isButtonDisabled || isLoading}
            >
              {isLoading ? (
                <CircularProgress size={20} className="loaderScreen" />
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

Otp.propTypes = {
  otpData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  emailData: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
