import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { Controller, useForm } from "react-hook-form";
import campaignService from "../../services/campaign.service";

export default function Campaign() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const naviate = useNavigate();

  useEffect(() => {
    campaign();
  }, []);

  const formatDate = (dateString) => {
    return moment(dateString).format("MM.DD.YYYY");
  };

  const campaign = async (searchData) => {
    setLoading(true)
    try {
      const response = await campaignService.getCampaign(searchData);
      if (response.data.status === 1) {
        setCampaignData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    campaign(data.search);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = campaignData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(campaignData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === currentPage ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pageNumbers.push(
          <div
            key={i}
            className={`paginationLength ${
              i === currentPage ? "PaginaitonActive" : ""
            }`}
            onClick={() => paginate(i)}
          >
            {i}
          </div>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pageNumbers.push(
          <div key={i} className="paginationLength">
            ...
          </div>
        );
      }
    }
    return pageNumbers;
  };

  return (
    <>
      <div className="container-fluid campaignHeight">
        <div className="row campaignSection">
          <div className="col-md-6">
            <h5 className="m-0 campaingTitleText">My Campaigns</h5>
          </div>
          <div className="col-md-6 ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="searchSection">
                <Controller
                  name="search"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="search"
                      placeholder="Enter words to filter ....."
                      className="customSearch"
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <p className="errorMessage">{errors.search.message}</p>
                )}
                <button>Search</button>
              </div>
            </form>
          </div>

          {loading ? (
            <div className="col-md-12 skeletonCampaign">
              {[1, 2, 3, 4, 5].map((screenIndex) => (
                <ContentLoader width={"100%"} height={75} key={screenIndex}>
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="75" />
                </ContentLoader>
              ))}
            </div>
          ) : currentItems.length > 0 ? (
            currentItems.map((campaign, index) => (
              <div className="" key={index}>
                <div
                  onClick={() =>
                    naviate(RoutesPaths.CAMPAIGNFOLLOWERS + "/" + campaign.id, {
                      state: { campaignName: campaign?.name },
                    })
                  }
                  className="campaignFollwesLink"
                >
                  <div className="col-md-12 p-0">
                    <div
                      className={`campaingnCard ${
                        !campaign.active ? "disabledCampaign" : ""
                      }`}
                    >
                      <div
                        className={`activeIcon ${
                          !campaign.active ? "disabledIcon" : ""
                        }`}
                      ></div>
                      <div className="camapingMainSection">
                        <h5 className="m-0">{campaign.name}</h5>
                        <div className="campaingdateTime">
                          <h6 className="m-0">
                            {campaign.active ? "Active" : "Stopped"}{" "}
                            {formatDate(campaign.activated)}
                          </h6>
                          <h6 className="m-0">
                            {campaign?.stats?.contacted_people} People in total
                          </h6>
                        </div>
                      </div>

                      <div className="stateDataMainSection">
                        <div className="statsDataSection">
                          <h6 className="m-0">Contacted</h6>
                          <h5 className="m-0">
                            {(
                              (campaign?.stats?.contacted_people /
                                campaign?.stats?.people_in_campaign) *
                              100
                            ).toFixed(2)}
                            %
                          </h5>
                          <h6 className="m-0">
                            {campaign?.stats?.contacted_people} to{" "}
                            {campaign?.stats?.people_in_campaign}
                          </h6>
                        </div>
                        <div className="statsDataSection">
                          <h6 className="m-0">Connected</h6>
                          <h5 className="m-0">
                            {(
                              (campaign?.stats?.connected /
                                campaign?.stats?.contacted_people) *
                              100
                            ).toFixed(2)}
                            %
                          </h5>
                          <h6 className="m-0">
                            {campaign?.stats?.connected} to{" "}
                            {campaign?.stats?.contacted_people}
                          </h6>
                        </div>
                        <div className="statsDataSection">
                          <h6 className="m-0">Replied to connection</h6>
                          <h5 className="m-0">
                            {(
                              (campaign?.stats?.replied_first_action /
                                campaign?.stats?.contacted_people) *
                              100
                            ).toFixed(2)}
                            %
                          </h5>
                          <h6 className="m-0">
                            {campaign?.stats?.replied_first_action} to{" "}
                            {campaign?.stats?.contacted_people}
                          </h6>
                        </div>
                        <div className="statsDataSection">
                          <h6 className="m-0">Replied to other</h6>
                          <h5 className="m-0">
                            {(
                              (campaign?.stats?.replied_other_actions /
                                campaign?.stats?.contacted_people) *
                              100
                            ).toFixed(2)}
                            %
                          </h5>
                          <h6 className="m-0">
                            {campaign?.stats?.replied_other_actions} to{" "}
                            {campaign?.stats?.contacted_people}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="campaignNotFound">No campaigns found</p>
          )}

          {!loading && campaignData.length > itemsPerPage && (
            <div className="paginationMainSection paginationPadding">
              <div>
                <h6 className="paginationItem">
                  Items : {campaignData?.length}
                </h6>
              </div>
              <div className="paginationInteger">
                <div
                  className={`borderSection ${currentPage === 1 && "disabled"}`}
                  onClick={() => paginate(currentPage - 1)}
                >
                  <img
                    src="/assets/img/arrowLeft.svg"
                    alt=""
                    className="logo"
                  />
                </div>
                {renderPageNumbers()}
                <div
                  className={`borderSection ${
                    currentPage === totalPages && "disabled"
                  }`}
                  onClick={() => paginate(currentPage + 1)}
                >
                  <img
                    src="/assets/img/arrowRight.svg"
                    alt=""
                    className="logo"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
