import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { backendUrl, backendUrlImg } from "../../Router/constant";
import toast from "react-hot-toast";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";

export default function Settings() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("/assets/img/profile.svg");
  const [userData, setUserData] = useState();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    userDetails();
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        setPreview(reader.result);
        try {
          const response = await axios.post(`${backendUrl}upload`, {
            name: base64String,
          });
          setSelectedFile(response.data);
          toast.success(response.data.message);
        } catch (error) {
          toast.error(error?.data || "Something went wrong");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const userDetails = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`${backendUrl}user/profile`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      if (response.data.status === 1) {
        setUserData(response.data.data);
        setPreview(backendUrlImg + response?.data?.data?.avatar);
      } 
    } catch {
      setPreview("/assets/img/profile.svg");
      setUserData();
    }
  };

  useEffect(() => {
    setValue("userName", userData?.username);
    setValue("firstName", userData?.firstname);
    setValue("lastName", userData?.lastname);
    setValue("about", userData?.bio);
    if (userData?.avatar) {
      setPreview(userData.avatar);
    }
  }, [setValue, userData]);

  const onSubmit = async (data) => {
    const payload = {
      username: data?.userName,
      firstname: data?.firstName,
      lastname: data?.lastName,
      bio: data?.about,
      avatar: selectedFile?.data,
    };
    try {
      const token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.patch(
        `${backendUrl}/user/profile`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (response.data.status === 1) {
        toast.success("Profile update successful");
        userDetails();
        setIsLoading(false);
      } else {
        toast.error("Something went wrong");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.data || "An error occurred");
      setIsLoading(false);
    }
  };

  const [quickMessages, setQuickMessages] = useState([""]);

  const handleAddMore = () => {
    setQuickMessages([...quickMessages, ""]);
  };  

  const handleRemove = (index) => {
    const newMessages = quickMessages.filter((_, idx) => idx !== index);
    setQuickMessages(newMessages);
  };

  const handleChange = (e, index) => {
    const newMessages = quickMessages.map((quickMessages, idx) =>
      idx === index ? e.target.value : quickMessages
    );
    setQuickMessages(newMessages);
  };

  return (
    <>
      <div className="container">
        <div className="row settingSection">
          <div className="col-md-8">
            <h6 className="settingTitle">Profile</h6>
            <p className="settingPera">
              This infromation will be displayed publicly so be careful what you
              share.
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="row">
              <div className="col-md-12 inputSection">
                <label className="inputLabel">User Name / Email</label>
                <Controller
                  name="userName"
                  control={control}
                  defaultValue=""
                  rules={{ required: "User Name is required" }}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
                {errors.userName && (
                  <p className="errorMessage">{errors.userName.message}</p>
                )}
              </div>
              <div className="col-md-6 inputSection">
                <label className="inputLabel">First Name</label>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{ required: "First Name is required" }}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
                {errors.firstName && (
                  <p className="errorMessage">{errors.firstName.message}</p>
                )}
              </div>
              <div className="col-md-6 inputSection">
                <label className="inputLabel">Last Name</label>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Last Name is required" }}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
                {errors.lastName && (
                  <p className="errorMessage">{errors.lastName.message}</p>
                )}
              </div>
              <div className="col-md-12 inputSection">
                <label className="inputLabel">About</label>
                <Controller
                  name="about"
                  control={control}
                  defaultValue=""
                  rules={{ required: "About section is required" }}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} />
                  )}
                />
                {errors.about && (
                  <p className="errorMessage">{errors.about.message}</p>
                )}
              </div>
              <p className="settingPera">
                Write a few sentences about yourself.
              </p>
              <div className="col-md-12">
                <h6 className="inputLabel">Photo</h6>
                {preview && (
                  <img src={backendUrlImg + preview} alt="user" className="chooseImage" />
                )}
                <input
                  type="file"
                  className="custom-file-input"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-md-12 SetingBtnSection">
                <Button color="secondary" type="button">
                  Cancel
                </Button>
                <Button className="submitBtn logOutBtn" type="submit">
                  {isLoading ? (
                    <CircularProgress size={20} className="loaderScreen" />
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
              </div>
            </form>

            <div className="row quickReply">
              <h6 className="settingTitle">Add Custom Messages</h6>
              <div className="col-md-12 inputSection">
                <label className="inputLabel">Quick Message</label>
                {quickMessages.map((quickMessages, index) => (
                  <div className="quickMessageSection" key="index">
                    <textarea
                      className="form-control quickMessage"
                      value={quickMessages || ""}
                      onChange={(e) => handleChange(e, index)}
                    ></textarea>
                    {index > 0 && (
                      <img
                        src="/assets/img/close-box.svg"
                        alt="icon"
                        className="closeIconQuick"
                        onClick={() => handleRemove(index)}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="col-md-12 SetingBtnSection">
                <Button
                  color="secondary"
                  className="addMorebtn"
                  onClick={handleAddMore}
                >
                  <img src="/assets/img/plusIcon.svg" alt="user" />
                  Add More
                </Button>
                <Button className="submitBtn">Save</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
