import React, { useEffect, useState } from "react";
import calanderService from "../services/calander.service";
import moment from "moment";

export default function UpComingMeeting() {
  const [isCallbackData, setCallbackData] = useState();

  useEffect(() => {
    getCalanderApi();
  }, []);

  const getCalanderApi = async () => {
    try {
      const getResponse = await calanderService.getCalander();
      if (getResponse.data.status === 1) {
        setCallbackData(getResponse.data.data);
      }
    } catch (error) {
      console.log("err", JSON.stringify(error));
    }
  };

  console.log("isCallbackDatadskfljdsklfjkldsf", isCallbackData);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {isCallbackData && isCallbackData.length > 0 ? (
            isCallbackData.map((event, index) => (
              <div className="col-md-4 rowFirstLastChild" key={index}>
                <div className="meetingCard">
                  <h6 className="m-0">{event.name}</h6>
                  <p className="m-0 boldTitle">Start Time : <span>{moment(event.start_time).format("DD-MM-YYYY hh:mm a")}</span></p>
                  <p className="m-0 boldTitle">End Time : <span>{moment(event.end_time).format("DD-MM-YYYY hh:mm a")}</span></p>
                  {/* <p className="m-0 locationText boldTitle">Location : <span>{event?.location?.location ? event?.location?.location : '-'}</span></p> */}
                  {/* <div className="copyLink">
                    <div className="linkBox">Copy Link</div>
                  </div> */}
                </div>
              </div>
            ))
          ) : (
            <p className="nodataFound">No events available</p>
          )}
        </div>
      </div>
    </>
  );
}
