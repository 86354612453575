import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import messageService from "../../services/message.service";
import ContentLoader from "react-content-loader";
import moment from "moment";
import {
  messenger_contact_status_choices,
  messenger_contact_status_choices_color,
  messenger_conversation_status_choices,
  messenger_conversation_status_choices_color,
  RoutesPaths,
} from "../../Router/constant";
import { useLocation, useNavigate } from "react-router-dom";

export default function Messages() {
  const [contacts, setContacts] = useState([]);
  const [contactstotal, setContactsTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState(false);
  const [singleUserProfile, setSingleUserProfile] = useState();
  const loadRef = useRef(true);
  const searchloadRef = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [activeContactIndex, setActiveContactIndex] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [recevedMsg, setrecevedMsg] = useState([]);
  const bottomRef = useRef(null);
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location?.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts && pathParts[2] ? parseInt(pathParts[2]) : "";
  // const isSideBar = location?.state?.isSideBar;

  useEffect(() => {
    contactList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, recevedMsg]);

  // useEffect(() => {
  //   if (contacts.length > 0) {
  //     const matchingContactIndex = contacts.findIndex(
  //       (contact) => contact.id === id
  //     );

  //     if (matchingContactIndex !== -1) {
  //       handleContactClick(
  //         contacts[matchingContactIndex],
  //         matchingContactIndex
  //       );
  //     }
  //   }
  // }, [contacts, id]);

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY hh:mm A");
  };

  const contactList = async (page = 1, pageSize = 10, searchData) => {
    setLoading(true);
    try {
      const response = await messageService.getCampaignsContact(
        page,
        pageSize,
        searchData
      );

      if (response.data.status === 1) {
        setContacts(response.data.data);
        setContactsTotal(response.data.total);

        if (loadRef.current) {
          const payload = { id: id };
          handleContactClick(id ? payload : response.data.data[0], 0);
        }
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleContactClick = async (contact, index) => {
    setMsgLoading(true);
    try {
      setActiveContactIndex(index > 0 ? index : 0);
      navigate(`${RoutesPaths.MESSAGES}/${contact.id}`);

      // First API call to get single user profile
      const userResponse = await messageService.getSingleMessage(contact?.id);
      if (userResponse.data.status === 1) {
        setSingleUserProfile(userResponse.data.data);
        if (id && loadRef?.current) {
          if (searchloadRef.current) {
            searchloadRef.current = false;
            setSearchQuery(userResponse?.data.data?.contact?.name);
            contactList(1, 10, userResponse?.data.data?.contact?.name);
          }
        }
        loadRef.current = false;
      } else {
        throw new Error("Failed to fetch single user profile");
      }

      // Second API call to get contact messages
      const msgResponse = await messageService.getContactMessage(contact?.id);
      if (msgResponse.data.status === 1) {
        setMessages([]); // Clear existing messages
        setrecevedMsg(msgResponse.data.data);
      } else {
        setMsgLoading(false);
      }
    } catch {
      setMsgLoading(false);
    } finally {
      setMsgLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setActiveContactIndex(null)
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 3; // Maximum number of visible pages
    const totalPages = Math.ceil(contactstotal / pageSize);

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (totalPages > maxVisiblePages) {
      if (endPage === totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
      } else if (startPage === 1) {
        endPage = maxVisiblePages;
      }
    }

    if (startPage > 1) {
      pageNumbers.push(
        <div
          key={1}
          className={`paginationLength ${
            currentPage === 1 ? "PaginaitonActive" : ""
          }`}
          onClick={() => handlePageChange(1)}
        >
          {1}
        </div>
      );
      if (startPage > 2) {
        pageNumbers.push(<div key="startEllipsis">...</div>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <div
          key={i}
          className={`paginationLength ${
            i === currentPage ? "PaginaitonActive" : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </div>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<div key="endEllipsis">...</div>);
      }
      pageNumbers.push(
        <div
          key={totalPages}
          className={`paginationLength ${
            currentPage === totalPages ? "PaginaitonActive" : ""
          }`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </div>
      );
    }

    return pageNumbers;
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async (id) => {
    if (message.trim()) {
      setMessages([...messages, { body: message }]);
      setMessage("");
      let payload = {
        message: message,
        messenger: id,
      };
      await messageService.postSendMessage(payload);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    contactList(1, 10, e.target.value);
  };

  return (
    <>
      <div className="container-fluid messageContainer">
        <div className="row">
          <div className="col-md-3 messsageUserListSection">
            <div className="searchSection messageSearch">
              <input
                type="search"
                placeholder="Search ....."
                className="customSearch messageSearchInput"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

            <div className="chatScrollSection">
              {loading ? (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 p-0 skeletonCampaign">
                      {[1, 2, 3, 4, 5].map((screenIndex) => (
                        <ContentLoader
                          width={"100%"}
                          height={75}
                          key={screenIndex}
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="100%"
                            height="75"
                          />
                        </ContentLoader>
                      ))}
                    </div>
                  </div>
                </div>
              ) : contacts.length > 0 ? (
                contacts.map((contact, index) => (
                  <div
                    key={index}
                    onClick={() => handleContactClick(contact, index)}
                  >
                    <div
                      className={`msgCardSection ${
                        index === activeContactIndex
                          ? "activeContactMessage"
                          : ""
                      }`}
                    >
                      <div className="messageProfile">
                        <img
                          src={contact?.contact?.image_base64}
                          alt="user"
                          className="logo"
                        />
                      </div>
                      <div className="messageRightSection">
                        <div className="">
                          <h5 className="m-0 messageUserName">
                            {contact?.contact?.name}
                          </h5>
                          <div className="dateIndicator">
                            <h6 className="m-0 msgDateSection">
                              {formatDate(contact?.last_datetime)}
                            </h6>
                            {/* <div className="messsageIndicator">5</div> */}
                          </div>
                        </div>
                        <p className="m-0 lastMsg">{contact.last_message}</p>
                        <div className="statusMessageSection">
                          <div className="alignSection statusMessageText">
                            <div
                              className="dotcontactStatus dotStatusMsg"
                              style={{
                                backgroundColor:
                                  messenger_contact_status_choices_color[
                                    contact?.contact_status
                                  ],
                              }}
                            ></div>
                            {
                              messenger_contact_status_choices[
                                contact?.contact_status
                              ]
                            }
                          </div>

                          <div className="alignSection statusMessageText">
                            <div
                              className="dotcontactStatus dotStatusMsg dotConversationStatus"
                              style={{
                                backgroundColor:
                                  messenger_conversation_status_choices_color[
                                    contact?.conversation_status
                                  ],
                              }}
                            ></div>
                            {
                              messenger_conversation_status_choices[
                                contact?.conversation_status
                              ]
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hrLine"></div>
                  </div>
                ))
              ) : (
                <p className="campaignNotFound">No Contact found</p>
              )}

              {contacts.length > 0 && (
                <div className="">
                  {/* <div>
                    <h6 className="paginationItem">Items : {contactstotal}</h6>
                  </div> */}
                  <div className="paginationInteger">
                    <div
                      className={`borderSection ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <img
                        src="/assets/img/arrowLeft.svg"
                        alt=""
                        className="logo"
                      />
                    </div>
                    {renderPageNumbers()}
                    <div
                      className={`borderSection ${
                        currentPage === Math.ceil(contactstotal / pageSize)
                          ? "disabled"
                          : ""
                      }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <img
                        src="/assets/img/arrowRight.svg"
                        alt=""
                        className="logo"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-9 headerMessageTypeBorder p-0">
            <div className="headerMessageType">
              <div className="messageProfileType">
                {msgLoading ? (
                  <>
                    <ContentLoader width={60} height={60}>
                      <rect
                        x="0"
                        y="0"
                        rx="50"
                        ry="50"
                        width="60"
                        height="60"
                      />
                    </ContentLoader>

                    <ContentLoader width={"50%"} height={60}>
                      <rect x="0" y="0" rx="5" ry="5" width="50%" height="60" />
                    </ContentLoader>
                  </>
                ) : (
                  <>
                    {singleUserProfile?.contact &&
                    singleUserProfile?.contact.image_base64 ? (
                      <img
                        src={singleUserProfile?.contact?.image_base64}
                        alt="user"
                        className="logo"
                      />
                    ) : (
                      <img
                        src="/assets/img/default-profile.jpg"
                        alt="default user"
                        className="logo"
                      />
                    )}

                    {singleUserProfile?.contact &&
                    singleUserProfile?.contact?.name ? (
                      <div>
                        <h5 className="messageUserName">
                          {singleUserProfile?.contact?.name}
                        </h5>
                        <div className="statusMessageSection justifySimple">
                          <div className="alignSection statusMessageText">
                            <div
                              className="dotcontactStatus dotStatusMsg"
                              style={{
                                backgroundColor:
                                  messenger_contact_status_choices_color[
                                    singleUserProfile?.contact_status
                                  ],
                              }}
                            ></div>
                            {
                              messenger_contact_status_choices[
                                singleUserProfile?.contact_status
                              ]
                            }
                          </div>

                          <div className="alignSection statusMessageText">
                            <div
                              className="dotcontactStatus dotStatusMsg dotConversationStatus"
                              style={{
                                backgroundColor:
                                  messenger_conversation_status_choices_color[
                                    singleUserProfile?.conversation_status
                                  ],
                              }}
                            ></div>
                            {
                              messenger_conversation_status_choices[
                                singleUserProfile?.conversation_status
                              ]
                            }
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h5>-------</h5>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="centerMessageSection">
              <div className="centerMessageSectionInner">
                {msgLoading ? (
                  <div className="sendMessageSkeleton">
                    <ContentLoader width={"100%"} height={35}>
                      <rect
                        x="0"
                        y="0"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="30"
                      />
                    </ContentLoader>
                    <ContentLoader width={"100%"} height={35}>
                      <rect
                        x="0"
                        y="0"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="30"
                      />
                    </ContentLoader>
                  </div>
                ) : (
                  <>
                    {[...recevedMsg, ...messages].map((msg, index) => {
                      if (msg.body !== "") {
                        return (
                          <div
                            key={index}
                            className={
                              msg.send_by_id === null
                                ? "recevedMessage"
                                : "recevedMessage sendMessage"
                            }
                          >
                            {msg.send_by_id === null ? (
                              <>
                                <p className="m-0">{msg.body}</p>
                              </>
                            ) : (
                              <>
                                <p className="m-0">{msg.body}</p>
                              </>
                            )}
                            <div ref={bottomRef}></div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
              </div>
            </div>

            <div className="messageFooterSection">
              {msgLoading ? (
                <ContentLoader width={"100%"} height={60}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
                </ContentLoader>
              ) : singleUserProfile?.contact_status === 1 ||
                singleUserProfile?.contact_status === 2 ? (
                <div className="messageSendSection">
                  <textarea
                    type="text"
                    className="messagetextInput"
                    placeholder="Type in a message"
                    rows="4"
                    cols="50"
                    value={message}
                    onChange={handleInputChange}
                  ></textarea>
                  <div className="sendTriggerMessage">
                    <Button className="submitBtn btn logOutBtn">
                      Quick Reply
                    </Button>
                    <img src="/assets/img/image.svg" alt="" className="logo" />
                    <img
                      src="/assets/img/sendIcon.svg"
                      alt=""
                      className="logo"
                      onClick={() => handleSendMessage(singleUserProfile.id)}
                      style={{ cursor: "pointer" }} // Added for better UX
                    />
                  </div>
                </div>
              ) : (
                <div className="messageSendSection">
                  You are not connected to this person and you cannot send them
                  further messages
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
