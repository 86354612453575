import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { nanoid } from "nanoid";
import { Row, Col, Button, Container } from "reactstrap";
import CustomModal from "../../Components/customDatetime";
import { useLocation, useNavigate } from "react-router-dom";
import { calendlyUrl, clientId, RoutesPaths } from "../../Router/constant";
import calanderService from "../../services/calander.service";
import moment from "moment";
import authService from "../../services/auth.service";
import ContentLoader from "react-content-loader";

export default function App() {
  const [modal, setModal] = useState(false);
  const calendarRef = useRef(null);
  const location = useLocation();
  const getCode = location.search;
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(null);
  const [calendlyToken, setCalendlyToken] = useState();
  const calendly = `${calendlyUrl}?client_id=${clientId}&response_type=code&redirect_uri=https://sherpai.us/calander`;

  const handleCloseModal = () => {
    setModal(false);
  };

  const [isCallbackData, setCallbackData] = useState();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await authService.verifyToken();
        setCalendlyToken(response.data.data.calendlyAccessToken);
      } catch (error) {
        console.error('Error verifying token:', error);
      }
    };

    verifyToken();
  }, []);

  useEffect(() => {
    if (calendlyToken) {
      getCalanderApi();
    }
  }, [calendlyToken]);

  const events =
    isCallbackData &&
    isCallbackData?.map((event) => ({
      title: event.name,
      start: event.start_time,
      end: event.end_time,
      extendedProps: {
        status: event.status,
        created_at: event.created_at,
        updated_at: event.updated_at,
        event_memberships: event.event_memberships,
        invitees_counter: event.invitees_counter,
        location: event.location,
        meeting_notes_html: event.meeting_notes_html,
        meeting_notes_plain: event.meeting_notes_plain,
      },
    }));

  useEffect(() => {
    const regex = /^\/calendar\/\d+$/;
    if (getCode?.split("code=")[1]) {
      callbackUrl(getCode?.split("code=")[1]);
    }
  }, []);

  const callbackUrl = async (id) => {
    try {
      const response = await calanderService.getCallbackUrl(id);
      if (response.data.status === 1) {
        navigate(RoutesPaths.CALANDER);
        await getCalanderApi();
      } else {
      }
    } catch (err) {
      console.log("err", JSON.stringify(err));
    }
  };

  const getCalanderApi = async () => {
    try {
      const getResponse = await calanderService.getCalander();
      if (getResponse.data.status === 1) {
        setCallbackData(getResponse.data.data);
      }
    } catch (error) {
      console.log("err", JSON.stringify(error));
    }
  };

  const handleEventClick = (clickInfo) => {
    setEventData(clickInfo.event);
    setModal(true);
  };
  return (
    <div className="App">
      <Container className="calanderSection">
        <Row style={{ marginBottom: 20 }}>
          <Col
            sm={{ size: 6, offset: 6 }}
            md={{ size: 6, offset: 6 }}
            style={{
              paddingRight: 15,
            }}
          >
            {!calendlyToken && (
              <Button style={{ float: "right" }} className="syncCalander">
                <a href={calendly}>
                  <img src="/assets/img/calendly.svg" alt="Sync Calendly" />
                  Sync Calendly
                </a>
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {events?.length > 0 ? (
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev,today,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                buttonText={{
                  today: "current",
                  month: "month",
                  week: "week",
                  day: "day",
                  list: "list",
                }}
                initialView="dayGridMonth"
                events={events}
                eventClick={handleEventClick}
                className="cursorPointer"
              />
            ) : (
              <ContentLoader
                height={500}
                width={"100%"}
              >
                {/* Customize the loader as per your design */}
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="500" />
              </ContentLoader>
            )}
          </Col>
        </Row>
      </Container>

      <CustomModal title="Event" isOpen={modal} toggle={handleCloseModal}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <h6 className="m-0 modalDataTitle">Title:</h6>&nbsp;
            </div>
            <div className="col-md-9">
              <p className="m-0">{eventData?.title}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <h6 className="m-0 modalDataTitle">Start Event:</h6>&nbsp;
            </div>
            <div className="col-md-9">
              <p className="m-0">
                {moment(eventData?.start).format("DD-MM-YYYY hh:mm a")}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <h6 className="m-0 modalDataTitle">End Event:</h6>&nbsp;
            </div>
            <div className="col-md-9">
              <p className="m-0">
                {moment(eventData?.end).format("DD-MM-YYYY hh:mm a")}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <h6 className="m-0 modalDataTitle">Location:</h6>&nbsp;
            </div>
            <div className="col-md-9">
              <p className="m-0">
                {eventData?.extendedProps?.location?.location || "-"}
              </p>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
