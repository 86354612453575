import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import { RoutesPaths } from "./constant";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Campaign from "../Pages/Campaign/Campaign";
import Campaignfollowers from "../Pages/Campaign/Campaignfollowers";
import Calander from "../Pages/Calander/Calander";
import Settings from "../Pages/Settings/Settings";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Messages from "../Pages/Message/Messages";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Signup from "../Pages/Auth/Signup";
import Accountnotfound from "../Pages/Auth/Accountnotfound";
import ResetPassword from "../Pages/Auth/ResetPassword";
import LinkedInOptimizer from "../Pages/LinkedInOptimizer/LinkedInOptimizer";

export default function RouteComponent() {
  return ( 
    <>
      <Routes>
        <Route exact path={RoutesPaths.LOGIN} element={<PublicRoute><Login /></PublicRoute>} />
        <Route exact path={RoutesPaths.SIGNUP} element={<PublicRoute><Signup /></PublicRoute>} />
        <Route exact path={RoutesPaths.FORGOTPASSWORD} element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route exact path={RoutesPaths.ACCOUNTNOTFOULD} element={<PublicRoute><Accountnotfound /></PublicRoute>} />
        <Route exact path={RoutesPaths.RESETPASSWORD} element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD} element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route exact path={RoutesPaths.CAMPAIGN} element={<PrivateRoute><Campaign /></PrivateRoute>} />
        <Route exact path={`${RoutesPaths.CAMPAIGNFOLLOWERS}/:id`} element={<PrivateRoute><Campaignfollowers /></PrivateRoute>} />
        <Route exact path={RoutesPaths.CALANDER} element={<PrivateRoute><Calander /></PrivateRoute>} />
        <Route exact path={RoutesPaths.SETTINGS} element={<PrivateRoute><Settings /></PrivateRoute>} />
        <Route exact path={RoutesPaths.LINKEDINOPTIMIZER} element={<PrivateRoute><LinkedInOptimizer /></PrivateRoute>} />
        <Route exact path={`${RoutesPaths.MESSAGEDETAIL}`} element={<PrivateRoute><Messages /></PrivateRoute>} />
        <Route exact path={`${RoutesPaths.MESSAGES}`} element={<PrivateRoute><Messages /></PrivateRoute>} />
        <Route path="*" element={<Navigate to={RoutesPaths.LOGIN} />} />
      </Routes> 
    </>
  );
}
