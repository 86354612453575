import { backendUrl } from "../Router/constant";
import axionIns from "../axios";

const getCalander = async () => {
  return axionIns.get(`${backendUrl}get-calendly-events`).then((response) => {
    return response;
  });
};

const getCallbackUrl = async (callbackData) => {
  return axionIns
    .post(`${backendUrl}calendly-authorize`, {
      calendlyAuthCode: callbackData,
    })
    .then((response) => {
      return response;
    });
};

export default {
  getCalander,
  getCallbackUrl,
};
