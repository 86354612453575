import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import HalfDoughnutChart from "../../Components/HalfDoughnutChart";
import { RoutesPaths } from "../../Router/constant";
import campaignService from "../../services/campaign.service";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import UpComingMeeting from "../../Components/UpComingMeeting";
import RecentConnection from "../../Components/RecentConnection";
import UnreadMessages from "../../Components/UnreadMessages";
// import { DateRangePicker } from "react-date-range";

export default function Dashboard() {
  const naviate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [emailloading, setEmailLoading] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  // const [emailCampaignData, setEmailCampaignData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    emailCampaign();
    campaign();
  }, []);

  useEffect(() => {
    emailSingleCampaign();
  }, []);

  const campaign = async () => {
    setLoading(true);
    try {
      const response = await campaignService.getCampaign();
      if (response.data.status === 1) {
        setCampaignData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const emailCampaign = async () => {
    setEmailLoading(true);
    try {
      const response = await campaignService.getEmailCampaign(1, 10);
      if (response.data.status === 1) {
        const campaignData = response.data.data;
        await emailSingleCampaign(campaignData);
      } else {
        setEmailLoading(false);
      }
    } catch {
      setEmailLoading(false);
    } finally {
      setEmailLoading(false);
    }
  };

  const emailSingleCampaign = async (campaignData) => {
    if (campaignData?.length > 0) {
      try {
        const emailCampaignIds = campaignData?.map((campaign) => campaign?.id);
        let singleCampaignData = [];

        for (const id of emailCampaignIds) {
          const response = await campaignService.getEmailCampaignDetails(id);
          if (response.data.status === 1) {
            singleCampaignData.push(response.data.data);
          }
        }
        const combinedData = campaignData?.map((campaign) => {
          const singleCampaign = singleCampaignData.find(
            (single) => single.id === campaign.id
          );
          return { ...campaign, ...singleCampaign };
        });
        setCombinedData(combinedData);
      } catch {
        setEmailLoading(false);
      } finally {
        setEmailLoading(false);
      }
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM.DD.YYYY");
  };

  return (
    <>
      <div className="container-fluid maxwidthSection">
        <div className="row dashboardsection">
          <div className="col-md-12">
            <Carousel autoPlay>
              <div className="sliderImage">
                <img src="/assets/img/banner.jpg" alt="banner" />
              </div>

              <div className="sliderImage">
                <img
                  src="/assets/img/Customer-Support-Outsourcing-Department.png"
                  alt="banner"
                />
              </div>
            </Carousel>
          </div>

          <div className="col-md-12">
            {/* <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect} singleDateRange={true}
            />{" "} */}
          </div>

          <div className="col-md-12">
            <div className="conatiner-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="doughnutChart">
                    <h6 className="m-0">Health Scrore (0-100)</h6>
                    <HalfDoughnutChart />
                    <p className="m-0">
                      Long before you sit down to put the make sure you breathe
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="doughnutChart">
                    <h6 className="m-0">Recent Connection</h6>
                    <RecentConnection />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="upcomingEvent">
                    <div className="recentHeader">
                      <h6 className="m-0 titleUpcoming">Unread Messages</h6>
                      <p className="m-0 badge">125</p>
                    </div>
                    <UnreadMessages />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="upcomingEvent">
              <h6 className="titleUpcoming">Upcoming meeting</h6>
              <div className="UpcomingScrollSection">
                <UpComingMeeting />
              </div>
            </div>
          </div>
          <div className="campaignSectionDashboard">
            <h6 className="tableSectiontitle">LinkedIn Campaign Report</h6>

            {loading ? (
              <div className="col-md-12 marginBottom">
                {[1, 2, 3, 4, 5].map((screenIndex) => (
                  <ContentLoader width={"100%"} height={75} key={screenIndex}>
                    <rect
                      x="0"
                      y="0"
                      rx="10"
                      ry="10"
                      width="100%"
                      height="75"
                    />
                  </ContentLoader>
                ))}
              </div>
            ) : campaignData.length > 0 ? (
              campaignData.map((campaign, index) => (
                <div key={index}>
                  <div
                    onClick={() =>
                      naviate(
                        RoutesPaths.CAMPAIGNFOLLOWERS + "/" + campaign.id,
                        {
                          state: { campaignName: campaign?.name },
                        }
                      )
                    }
                    className="campaignFollwesLink"
                  >
                    <div className="col-md-12 p-0">
                      <div
                        className={`campaingnCard ${
                          !campaign.active ? "disabledCampaign" : ""
                        }`}
                      >
                        <div
                          className={`activeIcon ${
                            !campaign.active ? "disabledIcon" : ""
                          }`}
                        ></div>
                        <div className="camapingMainSection">
                          <h5 className="m-0">{campaign.name}</h5>
                          <div className="campaingdateTime">
                            <h6 className="m-0">
                              {campaign.active ? "Active" : "Stopped"}{" "}
                              {formatDate(campaign.activated)}
                            </h6>
                            <h6 className="m-0">
                              {campaign?.stats?.contacted_people} People in
                              total
                            </h6>
                          </div>
                        </div>

                        <div className="stateDataMainSection">
                          <div className="statsDataSection">
                            <h6 className="m-0">Contacted</h6>
                            <h5 className="m-0">
                              {(
                                (campaign?.stats?.contacted_people /
                                  campaign?.stats?.people_in_campaign) *
                                100
                              ).toFixed(2)}
                              %
                            </h5>
                            <h6 className="m-0">
                              {campaign?.stats?.contacted_people} to{" "}
                              {campaign?.stats?.people_in_campaign}
                            </h6>
                          </div>
                          <div className="statsDataSection">
                            <h6 className="m-0">Connected</h6>
                            <h5 className="m-0">
                              {(
                                (campaign?.stats?.connected /
                                  campaign?.stats?.contacted_people) *
                                100
                              ).toFixed(2)}
                              %
                            </h5>
                            <h6 className="m-0">
                              {campaign?.stats?.connected} to{" "}
                              {campaign?.stats?.contacted_people}
                            </h6>
                          </div>
                          <div className="statsDataSection">
                            <h6 className="m-0">Replied to connection</h6>
                            <h5 className="m-0">
                              {(
                                (campaign?.stats?.replied_first_action /
                                  campaign?.stats?.contacted_people) *
                                100
                              ).toFixed(2)}
                              %
                            </h5>
                            <h6 className="m-0">
                              {campaign?.stats?.replied_first_action} to{" "}
                              {campaign?.stats?.contacted_people}
                            </h6>
                          </div>
                          <div className="statsDataSection">
                            <h6 className="m-0">Replied to other</h6>
                            <h5 className="m-0">
                              {(
                                (campaign?.stats?.replied_other_actions /
                                  campaign?.stats?.contacted_people) *
                                100
                              ).toFixed(2)}
                              %
                            </h5>
                            <h6 className="m-0">
                              {campaign?.stats?.replied_other_actions} to{" "}
                              {campaign?.stats?.contacted_people}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="campaignNotFound">No campaigns found</p>
            )}
          </div>

          <div className="col-md-12">
            <div className="outertableSection">
              <h6 className="tableSectiontitle">Campaign Email Report</h6>
              <div className="card-body pt-0">
                {/*begin::Table*/}
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr className="comapignTRtitle">
                        <th className="min-w-140px CampaignName">
                          Campaign name
                        </th>
                        <th className="text-center min-w-160px">
                          Email triggered
                        </th>
                        <th className="text-center min-w-80px">Delivered</th>
                        <th className="text-center min-w-100px">
                          Unique opens
                        </th>
                        <th className="text-center min-w-80px">
                          unique clicks
                        </th>
                        <th className="text-center min-w-100px">bounces</th>
                        <th className="text-center min-w-80px">unsuscribes</th>
                        <th className="text-center min-w-100px">
                          spam Reports
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                      {emailloading ? (
                        <tr>
                          <td colSpan="10">
                            <div className="col-md-12 marginBottom">
                              {[1, 2, 3, 4, 5].map((screenIndex) => (
                                <ContentLoader
                                  width={"100%"}
                                  height={75}
                                  key={screenIndex}
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    rx="10"
                                    ry="10"
                                    width="100%"
                                    height="75"
                                  />
                                </ContentLoader>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ) : combinedData.length > 0 ? (
                        combinedData.map((campaign, index) => (
                          <tr key={index} className="comapignTRData">
                            <td className="Datatitle CampaignName">
                              {campaign.name}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.statsinvalid_emails || "0"}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.delivered || "0"}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.unique_opens || "0"}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.unique_clicks || "0"}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.bounces || "0"}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.unsubscribes || "0"}
                            </td>
                            <td className="text-center">
                              {campaign?.stats?.spam_reports || "0"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="campaignNotFound">
                            No email campaigns found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}
              </div>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="chartSection">
              <h6 className="m-0">Detection</h6>
              <LineChart />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
