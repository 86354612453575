import { backendUrl } from "../Router/constant";
import axionIns from "../axios";

const getCampaignsContact = async (page,pageSize,searchData) => {
    return axionIns
    .get(`${backendUrl}campaign-contact?page=${page}&page_size=${pageSize}&search=${searchData?searchData:''}`,)
    .then((response) => {
      return response;
    });
}

const getContactMessage = async (id) => {
    return axionIns
    .get(`${backendUrl}messenger/${id}`,)
    .then((response) => {
      return response;
    });
}

const getSingleMessage = async (id) => {
  return axionIns
  .get(`${backendUrl}messenger-contact-detail/${id}`)
  .then((response) => {
    return response;
  });
}

const postSendMessage = async (payload) => {
  return axionIns
  .post(`${backendUrl}messenger`, payload)
  .then((response) => {
    return response;
  });
}
export default {
    getCampaignsContact,
    getContactMessage,
    postSendMessage,
    getSingleMessage
};