import React from 'react'

export default function UnreadMessages() {
    return (
        <>
            <div className="container-fluid recentScroll">
                <div className="row">
                    <div className="col-md-12 p-0 recentConnect">
                        <div className="recentImg">
                            <img src="assets/img/profile.jpg" alt="" />
                        </div>
                        <div>
                            <h6 className="m-0 recentName">established fact</h6>
                            <p className="m-0 recentPera">
                                as opposed to using 'Content here, content here', making it look
                                like readable English
                            </p>
                        </div>
                    </div>

                    <div className="col-md-12 p-0 recentConnect">
                        <div className="recentImg">
                            <img src="assets/img/home-office1.jpeg" alt="" />
                        </div>
                        <div>
                            <h6 className="m-0 recentName">established fact</h6>
                            <p className="m-0 recentPera">
                                as opposed to using 'Content here, content here', making it look
                                like readable English
                            </p>
                        </div>
                    </div>

                    <div className="col-md-12 p-0 recentConnect">
                        <div className="recentImg">
                            <img src="assets/img/banner.jpg" alt="" />
                        </div>
                        <div>
                            <h6 className="m-0 recentName">established fact</h6>
                            <p className="m-0 recentPera">
                                as opposed to using 'Content here, content here', making it look
                                like readable English
                            </p>
                        </div>
                    </div>

                    <div className="col-md-12 p-0 recentConnect">
                        <div className="recentImg">
                            <img src="assets/img/profile.jpg" alt="" />
                        </div>
                        <div>
                            <h6 className="m-0 recentName">established fact</h6>
                            <p className="m-0 recentPera">
                                as opposed to using 'Content here, content here', making it look
                                like readable English
                            </p>
                        </div>
                    </div>

                    <div className="col-md-12 p-0 recentConnect">
                        <div className="recentImg">
                            <img src="assets/img/Hometop2.jpg" alt="" />
                        </div>
                        <div>
                            <h6 className="m-0 recentName">established fact</h6>
                            <p className="m-0 recentPera">
                                as opposed to using 'Content here, content here', making it look
                                like readable English
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
