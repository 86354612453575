import React from "react";

export default function Footer() {
  return (
    <>
      <div className="container-fluid footerShadeSection">
        <div className="row">
          <div className="col-md-12 footerSection">
            <div>
              <h4 className="m-0 copyrightText">
                © 2024 Your Sherpa AI, Inc. All rights reserved.
              </h4>
            </div>
            {/* <div className="footerRightSection">
              <img src="/assets/img/facebook.svg" alt="facebook" />
              <img src="/assets/img/instagram.svg" alt="instagram" />
              <img src="/assets/img/twitter.svg" alt="twitter" />
              <img src="/assets/img/git.svg" alt="git" />
              <img src="/assets/img/youtube.svg" alt="youtube" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
