import React from "react";

export default function RecentConnection() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h6 className="connectValue">7,828</h6>
          <p className="connectionText">Your connections</p>
          <div className="recentConenctImg">
            <img src="assets/img/profile.jpg" alt=" " />
            <img src="assets/img/hometop_new3.jpg" alt=" " />
            <img src="assets/img/Hometop2.jpg" alt=" " />
            <img src="assets/img/profile.jpg" alt=" " />
            <img src="assets/img/profile.jpg" alt=" " />
            <img src="assets/img/profile.jpg" alt=" " />
            <div className="otheradd">+29</div>
          </div>
          <hr className="m-0"></hr>
          <div className="recentBottom">
            <p className="m-0">Find connections</p>
          </div>
        </div>
      </div>
    </>
  );
}
