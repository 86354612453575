import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { RoutesPaths } from "../../Router/constant";
import toast from "react-hot-toast";
import authService from "../../services/auth.service";
import Select from "react-select";

export default function Signup() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    const payload = {
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.email,
      password: data.password,
      phone: data.phoneno,
      calendlyLink: data.calendlyLink,
      linkdinProfile: data.linkedinprofile,
      currentBusinessType: currentBusiness?currentBusiness:'',
      bio: data.summary,
      targetClients: data.description,
      primaryIndustry: data.primaryindustry,
      roleType:
        data?.selectedRoleOptions?.map((item) =>
          item.value === "other" ? otherRoleInput : item.value
        ) || [],

      businessType: data?.selectedOptions?.map((item) =>
        item.value === "other" ? otherInput : item.value
      ) || [],
      state: data.stateorterritory,
      careerAchievements: data.careerdescription,
      experience: data.skillexperience,
      website: data.website,
      avgAvailability: data.availability,
    };

    try {
      setIsLoading(true);
      const response = await authService.signUpAction(payload);
      if (response.data.status === 1) {
        // localStorage.setItem("authToken", response.data.data.token);
        navigate(RoutesPaths.LOGIN);
        toast.success("Form submited");
        setIsLoading(false);
      } else {
        toast.error("Something went wrong");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.data || "An error occurred");
      setIsLoading(false);
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const [currentBusiness, setCurrentBusiness] = useState(null);

  const handleClick = (index,item) => {
    setActiveIndex(index);
    setCurrentBusiness(item)
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRoleOptions, setSelectedRoleOptions] = useState([]);

  const [otherInput, setOtherInput] = useState("");
  const [otherRoleInput, setOtherRoleInput] = useState("");

  const businessTypeOptions = [
    { value: "marketing", label: "Marketing (CMO)" },
    { value: "sales", label: "Sales (CSO)" },
    { value: "technology", label: "Technology (CTO)" },
    { value: "operations", label: "Operations (COO)" },
    { value: "financial", label: "Financial (CFO)" },
    { value: "digital", label: "Digital (CDO)" },
    { value: "information", label: "Information (CIO)" },
    { value: "legal", label: "Legal (CLO)" },
    { value: "people_hr", label: "People / HR (CHRO, CPO)" },
    { value: "product", label: "Product (CPO)" },
    { value: "other", label: "Other (Blank Field)" },
  ];

  const roleOption = [
    { value: "b2b", label: "B2B" },
    { value: "b2c", label: "B2C" },
    { value: "d2c", label: "D2C" },
    { value: "b2b2c", label: "B2B2C" },
    { value: "other", label: "Other" },
  ];

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };
  const handleOtherInputChange = (event) => {
    setOtherInput(event.target.value);
  };

  const handleSelectRoleAdd = (selected) => {
    setSelectedRoleOptions(selected);
  };
  const handleRoleInputChange = (event) => {
    setOtherRoleInput(event.target.value);
  };

  return (
    <>
      <div className="container-fluid containerBackground paddingLoginSection">
        <div className="row loginHeader">
          <div className="col-md-12 logoImg">
            <img
              src="/assets/img/Sherpa_logo.svg"
              alt="user"
              className="logo"
            />
            <h6 className="loginHeaderTitle">Create an account</h6>
          </div>
        </div>

        <div className="row formSection">
          <div className="col-md-5 formBackgroundSection">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">First Name</h6>
                      <Controller
                        name="firstname"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="First name"
                            className={`inputField ${
                              errors.firstname ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.firstname && (
                        <p className="errorMessage">
                          {errors.firstname.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Last Name</h6>
                      <Controller
                        name="lastname"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Last name"
                            className={`inputField ${
                              errors.lastname ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.lastname && (
                        <p className="errorMessage">
                          {errors.lastname.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Preferred Email</h6>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="email"
                            placeholder="Email id"
                            className={`inputField ${
                              errors.email ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.email && (
                        <p className="errorMessage">{errors.email.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        Preferred Phone Number
                      </h6>
                      <Controller
                        name="phoneno"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="phoneno"
                            placeholder="Preferred phone number"
                            className={`inputField ${
                              errors.phoneno ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.phoneno && (
                        <p className="errorMessage">{errors.phoneno.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        Calendly Link for Prospective Clients
                      </h6>
                      <Controller
                        name="calendlyLink"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Calendly link for prospective clients"
                            className={`inputField ${
                              errors.calendlyLink ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.calendlyLink && (
                        <p className="errorMessage">
                          {errors.calendlyLink.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        LinkedIn Profile URL: (URL Field)
                      </h6>
                      <Controller
                        name="linkedinprofile"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="LinkedIn profile"
                            className={`inputField ${
                              errors.linkedinprofile ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.linkedinprofile && (
                        <p className="errorMessage">
                          {errors.linkedinprofile.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 inputSection">
                    <div>
                      <h6 className="inputFieldTitle m-0">
                        Which best describes your current business?
                      </h6>
                      <div className="radiobtnSection">
                        {[
                          "Consulting",
                          "Fractional Executive",
                          "Board Member or Advisor",
                          "Executive Coaching",
                          "Interim Executive",
                          "Other",
                        ].map((item, index) => (
                          <div
                            key={index}
                            className={activeIndex === index ? "active" : ""}
                            onClick={() => handleClick(index,item)}
                            tabIndex={0} // This allows the div to be focusable
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        Professional Summary / Bio
                      </h6>
                      <Controller
                        name="summary"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            type="text"
                            placeholder="Professional summary"
                            className={`inputField textAreaField ${
                              errors.summary ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.summary && (
                        <p className="errorMessage">{errors.summary.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Provide a brief description of the types of clients you are looking to target</h6>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            type="text"
                            placeholder="Description"
                            className={`inputField textAreaField ${
                              errors.description ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.description && (
                        <p className="errorMessage">
                          {errors.description.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        What is/are the primary industry(ies) you support?:
                      </h6>
                      <Controller
                        name="primaryindustry"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Primary industry(ies) you support ..."
                            className={`inputField ${
                              errors.primaryindustry ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.primaryindustry && (
                        <p className="errorMessage">
                          {errors.primaryindustry.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Role</h6>
                      <Controller
                        name="selectedRoleOptions"
                        control={control}
                        rules={{
                          required: "Please select at least one role",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="ml-2 selectSection"
                            options={roleOption}
                            isMulti
                            placeholder="Select business type ...."
                            onChange={(selected) => {
                              field.onChange(selected);
                              handleSelectRoleAdd(selected);
                            }}
                            value={selectedRoleOptions}
                          />
                        )}
                      />
                      {errors.selectedRoleOptions && (
                        <span className="text-danger">
                          {errors.selectedRoleOptions.message}
                        </span>
                      )}

                      {selectedRoleOptions.some(
                        (option) => option.value === "other"
                      ) && (
                        <div className="mt-2">
                          <Controller
                            name="otherRoleInput"
                            control={control}
                            rules={{ required: "Please specify the role" }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                value={otherRoleInput}
                                onChange={(event) => {
                                  field.onChange(event);
                                  handleRoleInputChange(event);
                                }}
                                placeholder="Enter Role ..."
                                className="form-control inputField"
                              />
                            )}
                          />
                          {errors.otherRoleInput && (
                            <span className="text-danger">
                              {errors.otherRoleInput.message}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        Business Types Supported
                      </h6>
                      <Controller
                        name="selectedOptions"
                        control={control}
                        rules={{
                          required: "Please select at least one Business Types",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="ml-2 selectSection"
                            options={businessTypeOptions}
                            isMulti
                            placeholder="Select business type ...."
                            onChange={(selected) => {
                              field.onChange(selected);
                              handleSelectChange(selected);
                            }}
                            value={selectedOptions}
                          />
                        )}
                      />
                      {errors.selectedOptions && (
                        <span className="text-danger">
                          {errors.selectedOptions.message}
                        </span>
                      )}

                      {selectedOptions.some(
                        (option) => option.value === "other"
                      ) && (
                        <div className="mt-2">
                          <Controller
                            name="otherInput"
                            control={control}
                            rules={{
                              required: "Please specify the Business Types",
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                value={otherInput}
                                onChange={(event) => {
                                  field.onChange(event);
                                  handleOtherInputChange(event);
                                }}
                                placeholder="Enter business type ..."
                                className="form-control inputField"
                              />
                            )}
                          />
                          {errors.otherInput && (
                            <span className="text-danger">
                              {errors.otherInput.message}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        What achievements in your career are you most proud of?
                      </h6>
                      <Controller
                        name="careerdescription"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            type="text"
                            placeholder="Achievements in your career ..."
                            className={`inputField textAreaField ${
                              errors.careerdescription ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.careerdescription && (
                        <p className="errorMessage">
                          {errors.careerdescription.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                      State or Territory
                      </h6>
                      <Controller
                        name="stateorterritory"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Please enter state or territory ..."
                            className={`inputField ${
                              errors.stateorterritory ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.stateorterritory && (
                        <p className="errorMessage">
                          {errors.stateorterritory.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        What unique skills or experiences do you bring to the
                        table that set you apart from others in your field?
                      </h6>
                      <Controller
                        name="skillexperience"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            type="text"
                            placeholder="Skills or experiences do you bring to the table ..."
                            className={`inputField textAreaField ${
                              errors.skillexperience ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.skillexperience && (
                        <p className="errorMessage">
                          {errors.skillexperience.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Personal Website</h6>
                      <Controller
                        name="website"
                        control={control}
                        defaultValue="https://"
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            placeholder="Enter Website"
                            className={`inputField ${
                              errors.website ? "inputError" : ""
                            }`}
                          />
                        )}
                      />
                      {errors.website && (
                        <p className="errorMessage">{errors.website.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">
                        Average Availability (Hours Per Week):
                      </h6>
                      <Controller
                        name="availability"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            type="number"
                            placeholder="Enter average availability"
                            className={`inputField ${
                              errors.availability ? "inputError" : ""
                            }`}
                          />
                        )}
                      />
                      {errors.availability && (
                        <p className="errorMessage">
                          {errors.availability.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Password</h6>
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="password"
                            placeholder="Password"
                            className={`inputField ${
                              errors.password ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.password && (
                        <p className="errorMessage">
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="inputSection">
                      <h6 className="inputFieldTitle m-0">Confirm Password</h6>
                      <Controller
                        name="confirmpassword"
                        control={control}
                        defaultValue=""
                        rules={{
                          validate: (value) =>
                            value === watch("password") ||
                            "Passwords do not match",
                        }}
                        render={({ field }) => (
                          <input
                            type="password"
                            placeholder="Confirm password"
                            className={`inputField ${
                              errors.confirmpassword ? "inputError" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors.confirmpassword && (
                        <p className="errorMessage">
                          {errors.confirmpassword.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="submitButton">
                      <Button
                        type="submit"
                        className="submitBtn btnSection"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size={20}
                            className="loaderScreen"
                          />
                        ) : (
                          ""
                        )}
                        Sign up
                      </Button>
                    </div>
                    <div className="createAccountText">
                      Already have an account?{" "}
                      <NavLink
                        to={RoutesPaths.LOGIN}
                        className="decorationNone"
                      >
                        Log in
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
