import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap'
import { RoutesPaths } from '../../Router/constant';

export default function Accountnotfound() {
    const navigate = useNavigate();
    const handleCloseAlert = () => {
        navigate(RoutesPaths.LOGIN);    
    }

    return (
        <>
            <div className="container-fluid containerBackground paddingLoginSection">
                <div className="row loginHeader">
                    <div className="col-md-12 logoImg">
                        <img
                            src="/assets/img/Sherpa_logo.svg"
                            alt="user"
                            className="logo"
                        />
                        <h6 className="loginHeaderTitle">Oops account not found!</h6>
                    </div>
                </div>

                <div className="row formSection">
                    <div className="col-md-5 formBackgroundSection accountNotFound">
                        <h3 className="confirmationtitle">Alert</h3>
                        <h3 className="confirmationMessage">
                            Unfortunately, you cannot use our service without linking a LinkedIn account. Please connect your LinkedIn account to continue.
                        </h3>
                        <div className="modal-actions">
                            <Button onClick={handleCloseAlert} className="submitBtn btn logOutBtn">
                                Back
                            </Button>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
