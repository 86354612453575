import { backendUrl } from "../Router/constant";
import axionIns from "../axios";

const getCampaign = async (searchData) => {
  return axionIns
    .get(`${backendUrl}campaign?search=${searchData ? searchData : ""}`)
    .then((response) => {
      return response;
    });
};

const getCampaignsFollwers = async (id,page,pageSize,searchData) => {
    return axionIns
    .get(`${backendUrl}campaign-contact/${id}?page=${page}&page_size=${pageSize}&search=${searchData?searchData:''}`,)
    .then((response) => {
      return response;
    });
}

const getEmailCampaign = async (page,pageSize) => {
  return axionIns
  .get(`${backendUrl}email-campaign?page=${page}&page_size=${pageSize}`,)
  .then((response) => {
    return response;
  });
}

const getEmailCampaignDetails = async (id) => {
  return axionIns
  .get(`${backendUrl}email-campaign/${id}`,)
  .then((response) => {
    return response;
  });
}

const getHealthDetails = async () => {
  return axionIns
  .get(`${backendUrl}health-score`,)
  .then((response) => {
    return response;
  });
}

export default {
    getCampaign,
    getCampaignsFollwers,
    getEmailCampaign,
    getEmailCampaignDetails,
    getHealthDetails
};
