import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { RoutesPaths } from "../../Router/constant";
import { NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { Button } from "reactstrap";
import authService from "../../services/auth.service";

export default function Login() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    const payload = {
      username: data.email,
      password: data.password,
    };
    try {
      setIsLoading(true);

      const response = await authService.loginAction(payload);

      if (response.data.status === 1) {
        if (!response.data.data.liAccountId) {
          navigate(RoutesPaths.ACCOUNTNOTFOULD);
          setIsLoading(false);
        } else {
          localStorage.setItem("authToken", response.data.data.token);
          if (localStorage.getItem("isLogoutpath")) {
            navigate(localStorage.getItem("isLogoutpath"));
            localStorage.removeItem("isLogoutpath");
          } else {
            navigate(RoutesPaths.DASHBOARD);
          }
          toast.success("Login successful");
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.data || "An error occurred");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid containerBackground paddingLoginSection">
        <div className="row loginHeader">
          <div className="col-md-12 logoImg">
            <img
              src="/assets/img/Sherpa_logo.svg"
              alt="user"
              className="logo"
            />
            <h6 className="loginHeaderTitle">Sign in to your account</h6>
          </div>
        </div>

        <div className="row formSection">
          <div className="col-md-5 formBackgroundSection">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inputSection">
                <h6 className="inputFieldTitle m-0">Email</h6>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="email"
                      placeholder="Email Id"
                      className={`inputField ${
                        errors.email ? "inputError" : ""
                      }`}
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <p className="errorMessage">{errors.email.message}</p>
                )}
              </div>

              <div className="inputSection">
                <h6 className="inputFieldTitle m-0">Password</h6>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="password"
                      placeholder="Password"
                      className={`inputField ${
                        errors.password ? "inputError" : ""
                      }`}
                      {...field}
                    />
                  )}
                />
                {errors.password && (
                  <p className="errorMessage">{errors.password.message}</p>
                )}
              </div>

              <div className="radioForgotSection">
                <div className="radioSection">
                  <Controller
                    name="rememberMe"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="colorCheckbox"
                        {...field}
                      />
                    )}
                  />
                  <label
                    className="inputFieldTitle m-0 rememberme"
                    htmlFor="colorCheckbox"
                  >
                    Remember me
                  </label>
                </div>
                <NavLink
                  to={RoutesPaths.FORGOTPASSWORD}
                  className="decorationNone"
                >
                  <div>
                    <h5 className="forgotPassword m-0">Forgot Password?</h5>
                  </div>
                </NavLink>
              </div>

              <div className="submitButton">
                <Button
                  type="submit"
                  className="submitBtn btnSection"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={20} className="loaderScreen" />
                  ) : (
                    ""
                  )}
                  Sign In
                </Button>
              </div>
              <div className="createAccountText">
                Do not have an account or want to create a new one?{" "}
                <NavLink to={RoutesPaths.SIGNUP} className="decorationNone">
                  Sign up
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
