import axios from "axios";
import { backendUrl } from "./Router/constant";

const axionIns = axios.create({
  baseURL: backendUrl + "api/",
  headers: {
    "Content-Type": "application/json",
  },
});
// Set the AUTH token for any request
// const dispatch = useDispatch();
axionIns.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers["Authorization"] = token;

  return config;
});



export default axionIns;
