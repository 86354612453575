import { CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import authService from '../../services/auth.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesPaths } from '../../Router/constant';
import toast from 'react-hot-toast';

export default function ResetPassword() {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({});

    const location = useLocation();
    const { id } = location.state || {};
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    const onSubmit = async (data) => {
        const payload = {
            id: id,
            password: data.password,
            confirmPassword: data.confirmpassword,
        };
        try {
            setIsLoading(true);
            const response = await authService.resetPassword(payload);
            if (response.data.status === 1) {
                navigate(RoutesPaths.LOGIN);
                toast.success(response.data.data);
                setIsLoading(false);
            } else {
                toast.success(response.data.data);
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error?.data || "An error occurred");
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid containerBackground paddingLoginSection">
                <div className="row loginHeader">
                    <div className="col-md-12 logoImg">
                        <img
                            src="/assets/img/Sherpa_logo.svg"
                            alt="user"
                            className="logo forgotImage"
                        />
                    </div>
                </div>

                <div className="row formSection">
                    <div className="col-md-5 formBackgroundSection">
                        <h6 className="loginHeaderTitle">Reset Password ?</h6>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="inputSection">
                                <h6 className="inputFieldTitle m-0">Email</h6>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <input
                                            type="password"
                                            placeholder="Enter Password"
                                            className={`inputField ${errors.password ? "inputError" : ""
                                                }`}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.password && (
                                    <p className="errorMessage">{errors.password.message}</p>
                                )}
                            </div>

                            <div className="inputSection">
                                <h6 className="inputFieldTitle m-0">Confirm Password</h6>
                                <Controller
                                    name="confirmpassword"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        validate: (value) =>
                                            value === watch("password") ||
                                            "Passwords do not match",
                                    }}
                                    render={({ field }) => (
                                        <input
                                            type="password"
                                            placeholder="Confirm password"
                                            className={`inputField ${errors.confirmpassword ? "inputError" : ""
                                                }`}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.confirmpassword && (
                                    <p className="errorMessage">
                                        {errors.confirmpassword.message}
                                    </p>
                                )}
                            </div>

                            <div className="submitButton">
                                <Button type="submit" className="submitBtn btnSection">
                                    {isLoading ? (
                                        <CircularProgress size={20} className="loaderScreen" />
                                    ) : (
                                        ""
                                    )}
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}
