import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import { RoutesPaths } from "../../Router/constant";
import Otp from "../../Components/Otp";
import toast from "react-hot-toast";
import authService from "../../services/auth.service";
// import Otp from "../../Components/Otp";

export default function ForgotPassword() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShowOTP, setShowOTP] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const [emailData, setOtpEmail] = useState(null);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      username: data.email,
    };
    try {
      const response = await authService.forgotPassword(payload);
      if (response.data.status === 1) {
        setOtpData(response.data.data);
        setOtpEmail(payload);
        setShowOTP(true);
        setIsLoading(false);
        toast.success("OTP Sent successfully");
      } else {
        setIsLoading(false);
        toast.success("OTP Sent failed");
      }
    } catch (error) {
      toast.error(error?.data || "An error occurred");
      setIsLoading(false);
    }
  };

  const closeOtpModal = () => {
    setShowOTP(false);
    reset();
  };

  return (
    <>
      <div className="container-fluid containerBackground paddingLoginSection">
        <div className="row loginHeader">
          <div className="col-md-12 logoImg">
            <img
              src="/assets/img/Sherpa_logo.svg"
              alt="user"
              className="logo forgotImage"
            />
          </div>
        </div>

        <div className="row formSection">
          <div className="col-md-5 formBackgroundSection">
            <h6 className="loginHeaderTitle">Forgot Password ?</h6>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inputSection">
                <h6 className="inputFieldTitle m-0">Email</h6>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="email"
                      placeholder="Email Id"
                      className={`inputField ${
                        errors.email ? "inputError" : ""
                      }`}
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <p className="errorMessage">{errors.email.message}</p>
                )}
              </div>
              <div className="radioForgotSection forgotPasswordSingIn">
                <NavLink to={RoutesPaths.LOGIN} className="decorationNone">
                  <div>
                    <h5 className="forgotPassword m-0">Sign-In</h5>
                  </div>
                </NavLink>
              </div>

              <div className="submitButton">
                <Button type="submit" className="submitBtn btnSection">
                  {isLoading ? (
                    <CircularProgress size={20} className="loaderScreen" />
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>

        {isShowOTP && (
          <Otp otpData={otpData} emailData={emailData} onClose={closeOtpModal}/> 
        )}
      </div>
    </>
  );
}
