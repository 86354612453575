import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutesPaths } from './constant';

function PrivateRoute({ children }) {
  const location = useLocation();
  const token = localStorage.getItem('authToken') || null;

  if (!token) {
    if (location.pathname?.split('/')[1] === 'messages') {
      localStorage.setItem('isLogoutpath', location.pathname);
    } else {
      localStorage.removeItem('isLogoutpath');
    }
    // not logged in so redirect to login page with the return url
    return <Navigate to={RoutesPaths.LOGIN} />;
  }

  // authorized so return child components
  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
