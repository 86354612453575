import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js";
import campaignService from "../services/campaign.service";
import ContentLoader from "react-content-loader";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const HalfDoughnutChart = () => {
  const [isHealthDetails, setIsHealthDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const notFilledPercentage = 100 - (isHealthDetails || 0);
  const data = {
    labels: ["Filled", "Not Filled"],
    datasets: [
      {
        data: [isHealthDetails || 0, notFilledPercentage],
        backgroundColor: ["#36CE6A", "#E4FFED"],
        hoverBackgroundColor: ["#36CE6A", "#E4FFED"],
        borderWidth: 0,
      },
    ],
  };

  // Options for the chart
  const options = {
    cutout: "85%",
    circumference: 180,
    rotation: 270,
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: false,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    halfDoughnut();
  }, []);

  const halfDoughnut = async () => {
    setIsLoading(true);
    try {
      const response = await campaignService.getHealthDetails();
      if (response.data.status === 1) {
        setIsHealthDetails(response.data.data);
      } 
    } catch{
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="doughnutSection">
        {isLoading ? (
          <ContentLoader width={"100%"} height={110}>
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="110" />
          </ContentLoader>
        ) : (
          <>
            <Doughnut data={data} options={options} height={110} width={260} />
            <h5 className="doughnutText">{isHealthDetails}%</h5>
            <h5 className="doughnutText1">Total Score</h5>
          </>
        )}
      </div>
    </>
  );
};

export default HalfDoughnutChart;
