import { backendUrl } from "../Router/constant";
import axionIns from "../axios";

const loginAction = async (payload) => {
  return axionIns
    .post(backendUrl + "login",(payload))
    .then((response) => {
      return response;
    });
};

const signUpAction = async (payload) => {
  return axionIns
    .post(backendUrl + "register",(payload))
    .then((response) => {
      return response;
    });
};

    
const logOut = async () => {
  return axionIns
    .get(`${backendUrl}logout`)
    .then((response) => {
      return response;
    });
};

const verifyToken = async () => {
  return axionIns
  .post(`${backendUrl}verifyToken`)
  .then((response) => {
    return response;
  });
}

const notification = async (page,pageSize) => {
  return axionIns
  .get(`${backendUrl}notification?page=${page}&page_size=${pageSize}`)
  .then((response) => {
    return response;
  });
}

const forgotPassword = async (payload) => {
  return axionIns
    .post(backendUrl + "forget-password",(payload))
    .then((response) => {
      return response;
    });
};

const otpVerify = async (payload) => {
  return axionIns
    .post(backendUrl + "resetPasswordBeforeConfirmation",(payload))
    .then((response) => {
      return response;
    });
};

const resetPassword = async (payload) => {
  return axionIns
    .post(backendUrl + "resetPasswordAfterConfirmation",(payload))
    .then((response) => {
      return response;
    });
};

export default {
    loginAction,
    logOut,
    verifyToken,
    signUpAction,
    notification,
    forgotPassword,
    resetPassword,
    otpVerify
};
