import { Icon } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export default function LinkedInOptimizer() {
  return (
    <>
      <div className="container-fluid">
        <div className="row optimizedSection">
          <div className="col-md-12 optimizedSectionOne">
            <div className="optimizerHeader">
              <span className="optimizedTitle">
                You have already used 3 out of 5 possible optimizations
              </span>
              <Button className="leaveFeedback">
                <img src="assets/img/twinheart.svg" alt="" />
                Leave Feedback
              </Button>
            </div>
            <h2 className="h2Optimize">
              Optimize your LinkedIn Profile in minutes
            </h2>
            <p className="peraOptimize m-0">
              SherpAI built the LinkedIN Optimizer as a tool to help you
              streamline your profile to impress your target client profile.It
              is a source of ideas for you to improve the response you get from
              visitors. It uses the latest in artificial intelligence language
              models combined with the facts above to craft a few choices for
              each section of the profile.
            </p>
          </div>

          {/* <div className="col-md-12">
            <h4>LinkedIn Optimizer Instructions</h4>

            <div className="circleNumber">01</div>
            <div className="arrowLine">
              <div className="line"></div>
              <div class="triangle-right"></div>
            </div>
            <p>Upload your PDF of your LinkedIn profile</p>
          </div> */}
        </div>
      </div>
    </>
  );
}
